<template>
  <div class="base__content__page">
    <client-menu-wrapper v-if="hasMenu"></client-menu-wrapper>
    <div v-if="isShowAlert" class="navbar__alert__incomplete__wrapper">
      <div class="alert_profile_incomplete">
        <b-alert
          v-if="isIncompleteFlag"
          class="text-center mb-0"
          show
          variant="danger"
        >
          {{ $t("profile.label_profile_incomplete_flag") }}
        </b-alert>
      </div>
      <client-alert-renew-expired-date
        v-if="isMemberTypeRenewableFlag"
        class="alert_nenew_expired_date"
      />
    </div>
    <slot>
      <div
        :class="{ client__auth: isAuthClient }"
        class="base__content__page-primary__content"
      >
        <b-container fluid="xl" class="primary__background__color">
          <base-alert-system
            v-if="useAlertSystem && alert.show"
            :message="alert.message"
            :error="alert.error"
            @hide-alert="
              () => {
                alert.show = false;
              }
            "
          >
          </base-alert-system>
          <b-row class="d-flex w-100 m-0 p-0">
            <div
              class="base__content__page-primary__content-content__wrapper m-0 m-0 p-0 col-sm-12 col-sx-12 col-xl-12 col-md-12"
            >
              <slot name="content"> </slot>
            </div>
          </b-row>
        </b-container>
      </div>
    </slot>
    <!--  -->
    <DialogExpiredDate />
  </div>
</template>

<script>
import ClientMenuWrapper from "../Menu/ClientMenuWrapper.vue";
// import ClientLogoWrapper from '../Logo/ClientJbbfLogoWrapper.vue';
import { clientLayoutType } from "@/constants/commons";
import { mapGetters } from "vuex";
import {
  GET_CLIENT_ACCOUNT_INFO,
  GET_CLIENT_LOGIN_ORGANIZATION,
  GET_CLIENT_MEMBER_TYPES,
  GET_CLIENT_MENU_HEADER,
  GET_CLIENT_ACCOUNT_IS_AUTH,
  GET_PROFILE_INCOMPLETE_FLAG,
  GET_MEMBER_TYPE_RENEWABLE_FLAG,
} from "@/store/modules/AuthClient/types";
import useCalculateAlertIncompleteNavbar from "@/hooks/useCalculateCss";
import debounce from "lodash/debounce";
import ClientAlertRenewExpiredDate from "@/components/Client/Jbbf/RenewExpiredDate/ClientAlertRenewExpiredDate.vue";
import DialogExpiredDate from "@/components/Client/Jbbf/RenewExpiredDate/ClientAlertRenewExpiredDateDialog.vue";

export default {
  name: "ClientBaseViewContentPage",
  components: {
    ClientMenuWrapper,
    // ClientLogoWrapper,
    ClientAlertRenewExpiredDate,
    DialogExpiredDate,
  },
  props: {
    showMenu: {
      type: Boolean,
      description: "Hide or show menu",
      default: true,
    },
    useAlertSystem: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters("AuthClient", {
      accountInfoStore: GET_CLIENT_ACCOUNT_INFO,
      clientLoginOrganization: GET_CLIENT_LOGIN_ORGANIZATION,
      clientMemberTypes: GET_CLIENT_MEMBER_TYPES,
      menuHeader: GET_CLIENT_MENU_HEADER,
      isAuthClient: GET_CLIENT_ACCOUNT_IS_AUTH,
      isIncompleteFlag: GET_PROFILE_INCOMPLETE_FLAG,
      isMemberTypeRenewableFlag: GET_MEMBER_TYPE_RENEWABLE_FLAG,
    }),
    isHome() {
      return this.$route.name == "ClientHomeView";
    },
    hasMenu() {
      return this.showMenu && this.menuHeader.length;
    },
    isShowAlert() {
      return (
        this.isAuthClient &&
        (this.isMemberTypeRenewableFlag || this.isIncompleteFlag)
      );
    },
  },
  setup() {
    const { calculateContentPrimaryPaddingTop, getHeightOfElements } =
      useCalculateAlertIncompleteNavbar();
    return {
      calculateContentPrimaryPaddingTop,
      getHeightOfElements,
    };
  },
  data() {
    return {
      alert: {
        show: false,
        error: false,
        message: "",
      },
      moduleConfig: [],
      contentCmsList: [],
      clientLayoutType,
      loading: false,
    };
  },
  async mounted() {
    this.$eventBus.$on("update-alert-system", this.handleAlertSystem);
    await this.$nextTick();
    this.handlePaddingContent();
  },
  async created() {},
  methods: {
    handleAlertSystem({ error, message }) {
      this.alert = {
        show: true,
        error: error,
        message: message,
      };
    },
    handlePaddingContent() {
      const elmPrimaryContent = document.getElementsByClassName(
        "base__content__page-primary__content"
      )[0];
      if (elmPrimaryContent) {
        this.getHeightOfElements();
        elmPrimaryContent.style.paddingTop =
          this.calculateContentPrimaryPaddingTop + "px";
        window.addEventListener(
          "resize",
          debounce(() => {
            this.getHeightOfElements();
            elmPrimaryContent.style.paddingTop =
              this.calculateContentPrimaryPaddingTop + "px";
          }, 500)
        );
      }
    },
  },
};
</script>
