import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "corporates/staff/:corporate_code",
  name: "StaffManagement",
  component: () =>
    import(
      /* webpackChunkName: "staff-manage-layout-view" */ "@/views/Manage/Staff/LayoutView.vue"
    ),
  redirect: { name: "StaffSearching" },
  children: [
    {
      path: "",
      name: "StaffSearching",
      component: () =>
        import(
          /* webpackChunkName: "staff-manage-searching-view" */ "@/views/Manage/Staff/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create",
      name: "StaffCreate",
      component: () =>
        import(
          /* webpackChunkName: "staff-manage-create-view" */ "@/views/Manage/Staff/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:account_code",
      name: "StaffEdit",
      component: () =>
        import(
          /* webpackChunkName: "staff-manage-edit-view" */ "@/views/Manage/Staff/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "detail/:account_code",
      name: "StaffDetail",
      component: () =>
        import(
          /* webpackChunkName: "staff-manage-detail-view" */ "@/views/Manage/Staff/Detail.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "history-compare/:account_code",
      name: "StaffHistoryCompare",
      component: () =>
        import(
          /* webpackChunkName: "staff-manage-create-view" */ "@/views/Manage/Staff/CompareHistory.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
