import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "event-participants/:event_cms_code",
  name: "EventParticipantManage",
  component: () =>
    import(
      /* webpackChunkName: "event-participant-layout-view" */ "@/views/Manage/EventParticipant/LayoutView.vue"
    ),
  redirect: { name: "EventParticipantSearch" },
  children: [
    {
      path: "",
      name: "EventParticipantSearch",
      component: () =>
        import(
          /* webpackChunkName: "event-participant-searching-view" */ "@/views/Manage/EventParticipant/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/event/create
      name: "EventParticipantCreate",
      component: () =>
        import(
          /* webpackChunkName: "event-participant-create-view" */ "@/views/Manage/EventParticipant/Create.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/event/edit/1
      name: "EventParticipantEdit",
      component: () =>
        import(
          /* webpackChunkName: "event-participant-edit-view" */ "@/views/Manage/EventParticipant/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "import-csv/:event_code",
      name: "EventParticipantImportCsv",
      component: () =>
        import(
          /* webpackChunkName: "event-participant-import-csv-view" */ "@/views/Manage/EventParticipant/ImportCsvEventParticipant.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
