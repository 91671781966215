import {
  bffServiceInstance,
  bffServiceInstanceAdmin,
} from "@/plugins/api_service/services";
const prefix = "/management/admin-accounts";
const adminCsvPrefix = "/management/admin-csv";
import { state } from "@/socket";

export default {
  search: (query) => bffServiceInstance.get(`${prefix}/`, query),
  store: (bodyForm) => bffServiceInstance.post(`${prefix}`, {}, bodyForm),
  show: (id) => bffServiceInstance.get(`${prefix}/${id}`),
  update: (id, body) => bffServiceInstance.put(`${prefix}/${id}`, {}, body),
  remove: (id) => bffServiceInstance.delete(`${prefix}/${id}`),
  exportCsvManageAdmin: (socketEventName, query) =>
    bffServiceInstance.post(
      `${adminCsvPrefix}/export`,
      {},
      {
        socket_event_name: socketEventName,
        socket_id: state.socketId,
        ...query,
      }
    ),
  importCsvManageAdmin: (
    file,
    facility_code,
    login_organization,
    encode_type,
    channel_name
  ) => {
    let formData = new FormData();
    formData.append("file", file);
    formData.append("facility_code", facility_code);
    formData.append("login_organization", login_organization);
    formData.append("encode_type", encode_type);
    formData.append("socket_event_name", channel_name);
    formData.append("socket_id", state.socketId);

    return bffServiceInstance.post(`${adminCsvPrefix}/import`, {}, formData, {
      Accept: "*/*",
      "Content-Type": "multipart/form-data",
    });
  },
  // importCsvManageAdmin: (
  //   file,
  //   facility_code,
  //   login_organization,
  //   encode_type,
  //   channel_name
  // ) =>
  //   new Promise((resolve) => {
  //     setTimeout(() => {
  //       console.log(
  //         file,
  //         facility_code,
  //         login_organization,
  //         encode_type,
  //         channel_name
  //       );
  //       resolve("ok");
  //     }, 1000);
  //   }),

  getRoleSelection: ({ type }) =>
    bffServiceInstance.get(`/management/roles/get-select-list`, { type: type }),
  getRoleSelectionAdmin: ({ type, facility_code }) =>
    bffServiceInstanceAdmin.get(`/admin/roles/get-select-list`, {
      type: type,
      facility_code,
    }),
};
