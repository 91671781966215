/* eslint-disable */
import authManage from '@/middlewares/manage/auth';
import selectOrganization from '@/middlewares/manage/select-organization';

export default {
  path: 'members',
  name: 'MemberManage',
  component: () => import(/* webpackChunkName: "member-manage-layout-view" */'@/views/Manage/Member/LayoutView.vue'),
  redirect: { name: 'MemberSearch' },
  children: [
    {
      path: '',
      name: 'MemberSearch',
      component: () => import(/* webpackChunkName: "memeber-manage-searching-view" */'@/views/Manage/Member/Search.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      },
    },
    {
      path: 'import-csv',
      name: "MemberImportCsv",
      component: () => import(/* webpackChunkName: "member-manage-import-csv-view" */'@/views/Manage/Member/ImportCsv.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    },
    {
      path: 'create', // /manage/member/create
      name: 'MemberCreate',
      component: () => import(/* webpackChunkName: "member-manage-create-view" */'@/views/Manage/Member/Create.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      },
    },
    {
      path: 'edit/:id', // /manage/member/edit/1
      name: 'MemberEdit',
      component: () => import(/* webpackChunkName: "member-manage-create-view" */'@/views/Manage/Member/Edit.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      },
    },
    {
      path: 'detail/:id', // /manage/member/edit/1
      name: 'MemberDetail',
      component: () => import(/* webpackChunkName: "member-manage-create-view" */'@/views/Manage/Member/Detail.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      },
      props: {
        viewOnly: true
      }
    },
    {
      path: 'history-compare/:account_code',
      name: 'MemberHistoryCompare',
      component: () => import(/* webpackChunkName: "member-manage-create-view" */'@/views/Manage/Member/CompareHistory.vue'),
      meta: {
        middleware: [authManage, selectOrganization]
      }
    }
  ]
}