import i18n from "@/plugins/i18n";

export const roles = {};

export const planType = {
  registry: {
    value: 1,
    text: i18n.t("plan.label_member_registry_number"),
  },
  active: {
    value: 2,
    text: i18n.t("plan.label_member_active_number"),
  },
  organization: {
    value: 3,
    text: i18n.t("plan.label_organization_number"),
  },
};

// facility table
export const applicationType = {
  memberManagement: {
    value: 1,
    text: i18n.t("facility.label_application_type_member"),
  },
  uinionManagement: {
    value: 2,
    text: i18n.t("facility.label_application_type_uinion"),
  },
  customerManagement: {
    value: 3,
    text: i18n.t("facility.label_application_type_customer"),
  },
};

// facility table
export const businessType = {
  sport: {
    value: 1,
    text: i18n.t("facility.label_business_type_sport"),
  },
  nightSchool: {
    value: 2,
    text: i18n.t("facility.label_business_type_night_school"),
  },
  eduCenter: {
    value: 3,
    text: i18n.t("facility.label_business_type_edu_center"),
  },
  it: {
    value: 4,
    text: i18n.t("facility.label_business_type_it"),
  },
};

export const facilityUsingAppStatus = {
  notUse: {
    value: 0,
    text: i18n.t("facility.label_not_use_app"),
  },
  use: {
    value: 1,
    text: i18n.t("facility.label_can_use_app"),
  },
};

export const facilityStatus = {
  valid: {
    value: 0,
    text: i18n.t("facility.label_invalid_status"),
  },
  invalid: {
    value: 1,
    text: i18n.t("facility.label_valid_status"),
  },
};

export const menuType = {
  sidebar: {
    value: 1,
    text: i18n.t("master-menu.menu_sidebar"),
  },
  header: {
    value: 2,
    text: i18n.t("master-menu.menu_header"),
  },
};

export const platformType = {
  common: {
    value: 1,
    text: i18n.t("master-menu.common_screen"),
  },
  user: {
    value: 2,
    text: i18n.t("master-menu.user_screen"),
  },
  management: {
    value: 3,
    text: i18n.t("master-menu.management_screen"),
  },
  app: {
    value: 4,
    text: i18n.t("master-menu.app_screen"),
  },
};

export const menuManageType = {
  common: {
    value: 1,
  },
  event: {
    value: 2,
  },
};

export const paymentMethod = {
  bank_transfer: {
    value: "BANK_TRANSFER",
    text: i18n.t("payment-agency.bank_transfer_flag"),
  },
  account_transfer: {
    value: "ACCOUNT_TRANSFER",
    text: i18n.t("payment-agency.account_transfer_flag"),
  },
  combini: {
    value: "COMBINI",
    text: i18n.t("payment-agency.combini_flag"),
  },
  debit: {
    value: "DEBIT",
    text: i18n.t("payment-agency.debit_flag"),
    model: "form.bank_transfer_flag",
  },
  credit: {
    value: "CREDIT",
    text: i18n.t("payment-agency.credit_flag"),
  },
  payeasy: {
    value: "PAYEASY",
    text: i18n.t("payment-agency.payeasy_flag"),
  },
  paypal: {
    value: "PAYPAL",
    text: i18n.t("payment-agency.paypal_flag"),
  },
};

export const nonePaymentMethod = {
  value: 0,
  code: "NONE",
  text: i18n.t("payment-agency.none_method"),
};

//even csm
export const useType = {
  required: {
    value: 1,
    text: i18n.t("event-cms.use_type_required"),
  },
  optional: {
    value: 2,
    text: i18n.t("event-cms.use_type_optional"),
  },
  hide: {
    value: 3,
    text: i18n.t("event-cms.use_type_hide"),
  },
};

export const memberStatus = {
  all: {
    value: "",
    text: i18n.t("member.status_all"),
  },
  active: {
    value: 1,
    text: i18n.t("member.status_active"),
  },
  inactive: {
    value: 0,
    text: i18n.t("member.status_inactive"),
  },
};

export const genderType = {
  male: {
    value: 1,
    text: i18n.t("panel.label_gender_male"),
  },
  female: {
    value: 2,
    text: i18n.t("panel.label_gender_female"),
  },
  undefined: {
    value: 3,
    text: i18n.t("panel.label_gender_undefined"),
  },
};
export const memberCompetitionType = {
  fitness: {
    value: 1,
    text: i18n.t("member.label_member_type_fitness"),
  },
  gym: {
    value: 2,
    text: i18n.t("member.label_member_type_gym"),
  },
};

export const memberClassType = {
  personal: {
    value: 1,
    text: i18n.t("member.label_member_class_type_personal"),
  },
  company: {
    value: 2,
    text: i18n.t("member.label_member_class_type_company"),
  },
};

export const memberStatusRegistration = {
  temporary: {
    value: 1,
    text: i18n.t("member.label_member_status_registration_temporary"),
  },
  official: {
    value: 0,
    text: i18n.t("member.label_member_status_registration_official"),
  },
};

export const useTypeEventCmsComponent = {
  required: {
    value: 1,
    text: i18n.t("manage-event.label_required_input_component"),
  },
  nonRequired: {
    value: 2,
    text: i18n.t("manage-event.label_non_required_input_component"),
  },
  nonDisplay: {
    value: 3,
    text: i18n.t("manage-event.label_non_display_component"),
  },
};

export const useTypeEvtCheckList = {
  required: {
    value: 1,
    text: i18n.t("event-cms.label_event_component_use_type_required"),
  },
  nonRequired: {
    value: 2,
    text: i18n.t("event-cms.label_event_component_use_type_optional"),
  },
};

export const operatorType = {
  // greater then equals (>=)
  gte: {
    value: 1,
    text: i18n.t("panel.label_greater_than_equals"),
  },
  // smaller than equals (<=)
  mte: {
    value: 2,
    text: i18n.t("panel.label_smaller_than_equals"),
  },
  // equal (=)
  equal: {
    value: 3,
    text: i18n.t("panel.label_equals"),
  },
};

export const templateType = {
  common: {
    value: 1,
    text: i18n.t("mail-template.common"),
  },
  event: {
    value: 2,
    text: i18n.t("mail-template.event"),
  },
  content: {
    value: 3,
    text: i18n.t("mail-template.content"),
  },
  invoice: {
    value: 4,
    text: i18n.t("mail-template.invoice"),
  },
  account: {
    value: 5,
    text: i18n.t("mail-template.account"),
  },
};

export const sendMailFlag = {
  isSendFlag: 1,
  noSendFlag: 0,
};

export const accountAuthType = {
  account_auth: {
    value: 1,
    text: i18n.t("free-mail-template.account_auth_label"),
  },
  member_auth: {
    value: 2,
    text: i18n.t("free-mail-template.member_auth_label"),
  },
};

export const sexType = {
  male_gender: {
    value: 1,
    text: i18n.t("free-mail-template.male_gender_label"),
  },
  female_gender: {
    value: 2,
    text: i18n.t("free-mail-template.female_gender_label"),
  },
  other_gender: {
    value: 3,
    text: i18n.t("free-mail-template.other_gender_label"),
  },
};

export const freeMailTargetTab = {
  account: {
    value: 1,
    text: i18n.t("free-mail-template.account_tab"),
  },
  recipient: {
    value: 2,
    text: i18n.t("free-mail-template.recipient_tab"),
  },
};

export const freeMailStatus = {
  unsent: {
    value: 1,
    text: i18n.t("free-mail-template.unsent"),
  },
  sent: {
    value: 2,
    text: i18n.t("free-mail-template.sent"),
  },
};

export const sendEmailFlag = {
  send: {
    value: 1,
    text: i18n.t("member.label_send_email_flag"),
  },
  noSend: {
    value: 0,
    text: i18n.t("member.label_no_send_email_flag"),
  },
};

export const simpleSignupFlag = {
  send: {
    value: 1,
    text: i18n.t("account-setting.do"),
  },
  noSend: {
    value: 0,
    text: i18n.t("account-setting.do_not"),
  },
};

export const platFormType = {
  all: {
    value: 1,
    text: i18n.t("account-cms.label_platform_type_all"),
  },
  client: {
    value: 2,
    text: i18n.t("account-cms.label_platform_type_client"),
  },
  admin: {
    value: 3,
    text: i18n.t("account-cms.label_platform_type_admin"),
  },
};

export const extendInputType = {
  textBox: {
    value: "textbox",
    text: i18n.t("account-cms.label_extend_input_type_textbox"),
  },
  textarea: {
    value: "textarea",
    text: i18n.t("account-cms.label_extend_input_type_textarea"),
  },
  number: {
    value: "number",
    text: i18n.t("account-cms.label_extend_input_type_number"),
  },
  date: {
    value: "date",
    text: i18n.t("account-cms.label_extend_input_type_date"),
  },
  datetime: {
    value: "datetime",
    text: i18n.t("account-cms.label_extend_input_type_datetime"),
  },
  checkbox: {
    value: "checkbox",
    text: i18n.t("account-cms.label_extend_input_type_checkbox"),
  },
  radio: {
    value: "radio",
    text: i18n.t("account-cms.label_extend_input_type_radio"),
  },
  dropdown: {
    value: "dropdown",
    text: i18n.t("account-cms.label_extend_input_type_dropdown"),
  },
  dropdown_related: {
    value: "dropdown_related",
    text: i18n.t("account-cms.label_extend_input_type_dropdown_related"),
  },
  name: {
    value: "name",
    text: i18n.t("account-cms.label_extend_input_type_name"),
  },
  name_kana: {
    value: "name_kana",
    text: i18n.t("account-cms.label_extend_input_type_name_kana"),
  },
  location: {
    value: "location",
    text: i18n.t("account-cms.label_extend_input_type_location"),
  },
  yearMonth: {
    value: "year_month",
    text: i18n.t("account-cms.label_extend_input_type_year_month"),
  },
  decimal: {
    value: "decimal",
    text: i18n.t("account-cms.label_extend_input_type_decimal"),
  },
};

export const advFlag = {
  use: {
    value: 1,
    text: i18n.t("member.label_adv_use"),
  },
  notUse: {
    value: 0,
    text: i18n.t("member.label_adv_not_use"),
  },
};

export const invoiceStatus = {
  pending: {
    value: 1,
    text: i18n.t("invoice.pending"),
  },
  confirm: {
    value: 2,
    text: i18n.t("invoice.confirm"),
  },
};

export const invoiceSendStatus = {
  not_send: {
    value: 1,
    text: i18n.t("invoice.not_send"),
  },
  send: {
    value: 2,
    text: i18n.t("invoice.send"),
  },
  resend: {
    value: 3,
    text: i18n.t("invoice.resend"),
  },
};

export const invoicePaidStatus = {
  unpaid: {
    value: 1,
    text: i18n.t("invoice.unpaid"),
  },
  paid: {
    value: 2,
    text: i18n.t("invoice.paid"),
  },
  canceled: {
    value: 3,
    text: i18n.t("invoice.canceled"),
  },
};

export const paginationOption = {
  paginate_20: {
    value: 20,
    text: "20",
    selected: true,
  },
  paginate_50: {
    value: 50,
    text: "50",
  },
  paginate_100: {
    value: 100,
    text: "100",
  },
  paginate_all: {
    value: "",
    text: "全て",
  },
};

export const paidStatus = {
  not_paid: {
    value: 1,
    text: i18n.t("member.label_paid_status_not_paid"),
  },
  deposited: {
    value: 2,
    text: i18n.t("member.label_paid_status_deposited"),
  },
  cancel_deposited: {
    value: 3,
    text: i18n.t("member.label_paid_status_cancel_deposited"),
  },
};

export const logPaymentStatus = {
  pending: {
    value: 1,
  },
  success: {
    value: 2,
  },
  error: {
    value: 3,
  },
  cancelled: {
    value: 4,
  },
};

export const accountTemp = 1;

export const organizationType = {
  region_block: {
    value: 1,
    text: i18n.t("organization.label_organization_type_region"),
  },
  league: {
    value: 2,
    text: i18n.t("organization.label_organization_type_league"),
  },
  club: {
    value: 3,
    text: i18n.t("organization.label_organization_type_club"),
  },
};

export const feeMemberRegisterType = {
  free: {
    value: 1,
  },
  non_free: {
    value: 0,
  },
};

export const systemAminFlag = 1;

export const eventComponentsInputType = {
  textbox: {
    value: "textbox",
    text: i18n.t("manage-event.label_event_component_input_type_textbox"),
  },
  number: {
    value: "number",
    text: i18n.t("manage-event.label_event_component_input_type_number"),
  },
  date: {
    value: "date",
    text: i18n.t("manage-event.label_event_component_input_type_date"),
  },
  datetime: {
    value: "datetime",
    text: i18n.t("manage-event.label_event_component_input_type_datetime"),
  },
};

export const sendInvoiceFlag = {
  send: {
    value: 1,
    text: i18n.t("invoice.label_send_invoice_flag_send"),
  },
  unsend: {
    value: 0,
    text: i18n.t("invoice.label_send_invoice_flag_unsend"),
  },
};

export const typeCmsComponent = {
  default: {
    value: 1,
  },
  extend: {
    value: 2,
  },
};

export const cmsInputCode = {
  name: "name",
  summary: "summary",
  apply_period: "apply_period",
  open_period: "open_period",
  address: "address",
  public_period: "public_period",
  related_info: "related_info",
  related_document: "related_document",
  course: "course",
  course_option: "course_option",
  checklist: "checklist",
  result: "result",
  apply_condition: "apply_condition",
  component: "component",
  action: "action",
  host: "host",
  target_participant_organization: "target_participant_organization",
};

// mst_event/content column type
export const clientMyPageModuleType = {
  //trn_my_page_setting column module_type
  event_entried: 1,
  event_result: 2,

  // mst_content_cms column type
  slider: 1,
  advertise: 2,
  content: 3,
};

// trn_my_page_settings column type
export const clientMyPageType = {
  common: 1,
  event: 2,
  content: 3,
  invoice: 4,
  account: 5,
};

export const clientMyPageContentType = {
  slider: 1,
  banner: 2,
  news: 3,
};

export const clientLayoutType = {
  left: 1,
  right: 2,
};

export const contentCmsInputCode = {
  name: "name",
  member_type: "member_type",
  target_organization: "target_organization",
  public_period: "public_period",
  summary: "summary",
  related_info: "related_info",
  main_image: "main_image",
  related_document: "related_document",
  seq: "seq",
  group_flag: "group_flag",
  overview: "overview",
  media_kind: "media_kind",
  main_source: "main_source",
  digest_source: "digest_source",
};

export const limitSummary = 100;

export const initialBilling = {
  donot: {
    value: 0,
    text: i18n.t("payment-plan.do_not"),
  },
  do: {
    value: 1,
    text: i18n.t("payment-plan.do"),
  },
};

export const maturityMonthType = {
  join_month: {
    text: i18n.t("payment-plan.join_month"),
    value: 1,
  },
  monthly: {
    text: i18n.t("payment-plan.monthly"),
    value: 2,
  },
};

export const maturityDayType = {
  joining_day: {
    text: i18n.t("payment-plan.joining_day"),
    value: 1,
  },
  the_beginning_of_the_month: {
    text: i18n.t("payment-plan.the_beginning_of_the_month"),
    value: 2,
  },
  end_of_month: {
    text: i18n.t("payment-plan.end_of_month"),
    value: 3,
  },
  designated_date: {
    text: i18n.t("payment-plan.designated_date"),
    value: 4,
  },
};

export const invoiceTaxRate = {
  0: "0%",
  8: "8%",
  10: "10%",
};

export const operator = {
  all: {
    text: i18n.t("payment-plan.operator_all"),
    value: 1,
  },
  lesser: {
    text: i18n.t("payment-plan.operator_lesser"),
    value: 2,
  },
  equal: {
    text: i18n.t("payment-plan.operator_equal"),
    value: 3,
  },
  greater: {
    text: i18n.t("payment-plan.operator_greater"),
    value: 4,
  },
};

export const contentCmsType = {
  slider: {
    value: 1,
    text: i18n.t("content-cms.slider"),
  },
  advertisement: {
    value: 2,
    text: i18n.t("content-cms.advertisement"),
  },
  content: {
    value: 3,
    text: i18n.t("content-cms.content"),
  },
};

export const accountCmsUseType = {
  required: {
    value: 1,
    text: i18n.t("account-cms.use_type_required"),
  },
  optional: {
    value: 2,
    text: i18n.t("account-cms.use_type_optional"),
  },
  hide: {
    value: 3,
    text: i18n.t("account-cms.use_type_hide"),
  },
  requiredRelated: {
    value: 4,
    text: i18n.t("account-cms.use_type_required_related"),
  },
  displayRelated: {
    value: 5,
    text: i18n.t("account-cms.use_type_seen_related"),
  },
  displayAndRequiredRelated: {
    value: 6,
    text: i18n.t("account-cms.use_type_display_related"),
  },
};

export const dependencyOperatorType = {
  more: {
    value: 1,
    text: i18n.t("account-cms.label_dependency_operator_type_more"),
    description: ">=",
  },
  less: {
    value: 2,
    text: i18n.t("account-cms.label_dependency_operator_type_less"),
    description: "<=",
  },
  equal: {
    value: 3,
    text: i18n.t("account-cms.label_dependency_operator_type_equal"),
    description: "=",
  },
  contain: {
    value: 4,
    text: i18n.t("account-cms.label_dependency_operator_type_contain"),
    description: "IN",
  },
  except: {
    value: 5,
    text: i18n.t("account-cms.label_dependency_operator_type_except"),
    description: "NOT IN",
  },
};

export const customizeLayout = {
  left: {
    value: 1,
    description: "Left",
  },
  right: {
    value: 2,
    description: "Right",
  },
  module: {
    value: null,
    description: "Module",
  },
};

export const customizeMasterCategoryType = {
  common: {
    value: 1,
    text: i18n.t("master-category-customization.common_type"),
  },
  event: {
    value: 2,
    text: i18n.t("master-category-customization.event_type"),
  },
  content: {
    value: 3,
    text: i18n.t("master-category-customization.content_type"),
  },
  request: {
    value: 4,
    text: i18n.t("master-category-customization.request_type"),
  },
  account: {
    value: 5,
    text: i18n.t("master-category-customization.account_type"),
  },
};

export const inputTypeEventComponent = {
  textbox: {
    value: "textbox",
    text: i18n.t("account-cms.label_extend_input_type_textbox"),
    // text: 'textbox',
    default_value: "",
  },
  textarea: {
    value: "textarea",
    text: i18n.t("event-cms.label_input_type_textarea"),
    // text: 'textarea',
    default_value: "",
  },
  number: {
    value: "number",
    text: i18n.t("account-cms.label_extend_input_type_number"),
    // text: 'number',
    default_value: "",
  },
  date: {
    value: "date",
    text: i18n.t("account-cms.label_extend_input_type_date"),
    // text: 'date',
    default_value: "",
  },
  datetime: {
    value: "datetime",
    text: i18n.t("account-cms.label_extend_input_type_datetime"),
    // text: 'datetime',
    default_value: "",
  },
  checkbox: {
    value: "checkbox",
    text: i18n.t("account-cms.label_extend_input_type_checkbox"),
    // text: 'checkbox',
    default_value: [],
  },
  radio: {
    value: "radio",
    text: i18n.t("account-cms.label_extend_input_type_radio"),
    // text: 'radio',
    default_value: [],
  },
  dropdown: {
    value: "dropdown",
    text: i18n.t("account-cms.label_extend_input_type_dropdown"),
    // text: 'dropdown',
    default_value: [],
  },
  year_month: {
    value: "year_month",
    text: i18n.t("account-cms.label_extend_input_type_year_month"),
    // text: 'year_month',
    default_value: "",
  },
  decimal: {
    value: "decimal",
    text: i18n.t("account-cms.label_decimal_input"),
    // text: 'decimal',
    default_value: "",
  },
};

export const componentMasterOptionFlag = {
  manual: {
    value: 0,
  },
  master: {
    value: 1,
  },
};

export const eventComponentUseTypeSetting = {
  required: {
    value: 1,
    text: i18n.t("event.label_use_type_required"),
  },
  option: {
    value: 2,
    text: i18n.t("event.label_use_type_option"),
  },
  required_dependency: {
    value: 4,
    text: i18n.t("event.label_use_type_required_dependency"),
  },
  display_dependency: {
    value: 5,
    text: i18n.t("event.label_use_type_display_dependency"),
  },
  required_and_display_dependency: {
    value: 6,
    text: i18n.t("event.label_use_type_required_and_display_dependency"),
  },
};

export const conditionTypeInputDependency = {
  accountCmsDefault: {
    value: 1,
  },
  accountCmsExtend: {
    value: 2,
  },
  eventComponent: {
    value: 3,
  },
};

export const settingCsvType = {
  event: {
    value: 2,
    text: i18n.t("setting.label_event_csv_type"),
  },
  account: {
    value: 5,
    text: i18n.t("setting.label_account_csv_type"),
  },
};

export const roleModuleType = {
  manager: {
    value: 1,
    text: i18n.t("role.manager"),
  },
  member: {
    value: 2,
    text: i18n.t("role.member"),
  },
};

export const onboardingPermissionCode = {
  camera: {
    value: "camera",
    text: i18n.t("onboarding.camera"),
  },
  notification: {
    value: "notification",
    text: i18n.t("onboarding.notification"),
  },
  location: {
    value: "location",
    text: i18n.t("onboarding.location"),
  },
  contact: {
    value: "contact",
    text: i18n.t("onboarding.contact"),
  },
  photo: {
    value: "photo",
    text: i18n.t("onboarding.photo"),
  },
  storage: {
    value: "storage",
    text: i18n.t("onboarding.storage"),
  },
  calendar: {
    value: "calendar",
    text: i18n.t("onboarding.calendar"),
  },
  reminder: {
    value: "reminder",
    text: i18n.t("onboarding.reminder"),
  },
  mic: {
    value: "mic",
    text: i18n.t("onboarding.mic"),
  },
  file_folder: {
    value: "file_folder",
    text: i18n.t("onboarding.file_folder"),
  },
};

export const maxValueNumber = 4294967295;

export const defaultSizeQr = {
  width: 300,
  height: 300,
};

export const multiEntryFlag = {
  radio: {
    value: 0,
  },
  checkbox: {
    value: 1,
  },
};

export const titlePlatformType = {
  manage: {
    value: 3,
    text: i18n.t("account-permission.manage_module"),
  },
  web: {
    value: 2,
    text: i18n.t("account-permission.web_module"),
  },
  app: {
    value: 4,
    text: i18n.t("account-permission.app_module"),
  },
};

export const targetPermissionType = {
  not_allow: {
    value: 0,
    text: i18n.t("account-permission.not_allow"),
  },
  view_flag: {
    value: 1,
    text: i18n.t("account-permission.view_flag"),
  },
  edit_flag: {
    value: 2,
    text: i18n.t("account-permission.edit_flag"),
  },
  view_edit_flag: {
    value: 3,
    text: i18n.t("account-permission.view_edit_flag"),
  },
};

export const accountPermissionType = {
  role: {
    value: 2,
    text: i18n.t("account.permission_type.role"),
  },
  individual: {
    value: 3,
    text: i18n.t("account.permission_type.individual"),
  },
};

export const iconType = {
  emoji: {
    value: 1,
    text: i18n.t("functional-menu.label_icon_emoji"),
  },
  image: {
    value: 2,
    text: i18n.t("functional-menu.label_icon_image"),
  },
  none: {
    value: 3,
    text: i18n.t("functional-menu.label_none_icon"),
  },
};

export const isDefault = {
  yes: {
    value: 1,
    text: i18n.t("billing-address.yes"),
  },
  no: {
    value: 0,
    text: i18n.t("billing-address.no"),
  },
};

export const isMultiEntryFlag = {
  no: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
  yes: {
    value: 1,
    text: i18n.t("panel.do"),
  },
};

export const permissionType = {
  none: {
    value: 1,
    text: i18n.t("account.label_none"),
  },
  label_individual_designation: {
    value: 3,
    text: i18n.t("account.label_individual_designation"),
  },
  role: {
    value: 2,
    text: i18n.t("account.label_role"),
  },
};

export const buttonTypes = {
  member_role: {
    value: "member_role",
    title: i18n.t("account-setting.button_member_role"),
    modal_size: "800",
  },
  temp_flag: {
    value: "temp_flag",
    title: i18n.t("account-setting.button_member_temp_flag"),
    modal_size: "500",
  },
  expired_type: {
    value: "expired_type",
    title: i18n.t("account-setting.button_member_expired_type"),
    modal_size: "1175",
  },
  prefix_code: {
    value: "prefix_code",
    title: i18n.t("account-setting.button_member_prefix_code"),
    modal_size: "800",
  },
  send_invoice_platform: {
    value: "send_invoice_platform",
    title: i18n.t("account-setting.button_member_send_invoice_platform_type"),
    modal_size: "800",
  },
  send_complete_mail_platform: {
    value: "send_complete_mail_platform",
    title: i18n.t("account-setting.member_send_complete_mail_platform_type"),
    modal_size: "800",
  },
  default_member_type: {
    value: "default_member_type",
    title: i18n.t("account-setting.initial_membership_type"),
    modal_size: "800",
  },
};

export const sendInvoicePlatform = {
  common: {
    value: 1,
    text: i18n.t("account-setting.common_screen"),
  },
  user: {
    value: 2,
    text: i18n.t("account-setting.user_screen"),
  },
  admin: {
    value: 3,
    text: i18n.t("account-setting.admin_screen"),
  },
};

export const expiredType = {
  not_use: {
    value: 0,
    text: i18n.t("account-setting.not_use"),
  },
  year: {
    value: 1,
    text: i18n.t("account-setting.year"),
  },
  annual: {
    value: 2,
    text: i18n.t("account-setting.annual"),
  },
};

export const menuStyleType = {
  1: {
    description: "standard",
    value: 5,
    text: i18n.t("setting.label_style_type_standard"),
    img_description: "/img/menu-style-setting/standard.png",
    text_description: `<p class="font-weight-bold" style="font-size: 14px">${i18n.t(
      "setting.label_style_type_standard_description"
    )}</p>`,
  },
  2: {
    description: "motion",
    value: 5,
    text: i18n.t("setting.label_style_type_animation"),
    img_description: "/img/menu-style-setting/motion.png",
    text_description: `<p class="font-weight-bold" style="font-size: 14px">${i18n.t(
      "setting.label_style_type_standard_description"
    )}</p>`,
  },
  3: {
    description: "expandable",
    value: 9,
    text: i18n.t("setting.label_style_type_extend"),
    img_description: "/img/menu-style-setting/expandable.png",
    text_description: `<div class="font-weight-bold" style="font-size: 14px">
      <div>${i18n.t("setting.label_style_type_extend_description")}</div>
      <div>${i18n.t("setting.label_style_type_extend_description_detail")}</div>
    </div>`,
  },
};

export const listStyleType = {
  1: {
    description: "magazine",
    value: 1,
    text: i18n.t("setting.label_style_type_magazine"),
    img_description: "/img/list-style-setting/magazine.png",
    text_description: "",
  },
  2: {
    description: "small_card",
    value: 2,
    text: i18n.t("setting.label_style_type_small_card"),
    img_description: "/img/list-style-setting/small_card.png",
    text_description: "",
  },
  3: {
    description: "large_card",
    value: 3,
    text: i18n.t("setting.label_style_type_large_card"),
    img_description: "/img/list-style-setting/large_card.png",
    text_description: "",
  },
};

export const accountVerifyEmailFlag = {
  do: {
    value: 1,
    text: i18n.t("panel.do"),
  },
  not_do: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
};

export const facilityUseConfigOption = {
  not_used: {
    value: 0,
    text: i18n.t("account-setting.not_use"),
  },
  used: {
    value: 1,
    text: i18n.t("account-setting.use"),
  },
};

export const flag = {
  notUse: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
  use: {
    value: 1,
    text: i18n.t("panel.do"),
  },
};

export const tempFlag = {
  do: {
    value: 1,
    text: i18n.t("panel.do"),
  },
  do_not: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
};

export const storageCodes = {
  gcs: {
    value: "GCS",
    text: "GCS",
  },
  s3: {
    value: "S3",
    text: "AWS S3",
  },
};

export const screenClient = {
  profile: "profile",
  register: "register",
};

export const encodeCsvOptions = {
  utf_8: {
    value: "UTF-8",
    text: "UTF-8",
  },
  shift_jis: {
    value: "SJIS",
    text: "SHIFT-JIS",
  },
};

export const csvHistoryType = {
  import: 1,
  export: 2,
};

export const corporatePrincipleFlag = {
  responsible: {
    value: 0,
    text: i18n.t("account-cms.staff"),
  },
  representative: {
    value: 1,
    text: i18n.t("account-cms.principle"),
  },
};

export const prefixAccountCorporateCode = {
  account: "A",
  corporate: "C",
};
export const financialBalanceTypeOptions = {
  year: {
    value: 1,
    text: i18n.t("facility.year"),
  },
  yearly: {
    value: 2,
    text: i18n.t("facility.yearly"),
  },
};

export const nextFinancialPresignupMonth = {
  1: {
    value: 1,
    text: `1${i18n.t("account-setting.month_ago")}`,
  },
  2: {
    value: 2,
    text: `2${i18n.t("account-setting.month_ago")}`,
  },
  3: {
    value: 3,
    text: `3${i18n.t("account-setting.month_ago")}`,
  },
};

export const financialBalanceType = {
  year: {
    value: 1,
  },
  annual: {
    value: 2,
  },
};

export const renewExpiredAlertBeforeMonth = {
  1: {
    value: 1,
    text: `1${i18n.t("account-setting.month_ago")}`,
  },
  2: {
    value: 2,
    text: `2${i18n.t("account-setting.month_ago")}`,
  },
  3: {
    value: 3,
    text: `3${i18n.t("account-setting.month_ago")}`,
  },
};

export const paymentPlanType = {
  common: {
    value: 1,
    text: i18n.t("payment-plan.common"),
  },
  signupFee: {
    value: 2,
    text: i18n.t("payment-plan.signup_fee"),
  },
};

export const recurringBillingFlag = {
  donot: {
    value: 0,
    text: i18n.t("payment-plan.do_not"),
  },
  do: {
    value: 1,
    text: i18n.t("payment-plan.do"),
  },
};

export const clientScreenCms = {
  profile: "profile",
  register: "register",
  recreate: "recreate",
};

export const skipMatchCondition = 1;

export const accessTemplateAvailableType = {
  unspecified: {
    value: 1,
    text: i18n.t("access-template.unspecified"),
  },
  specify_number: {
    value: 2,
    text: i18n.t("access-template.specify_number"),
  },
  period_specification: {
    value: 3,
    text: i18n.t("access-template.period_specification"),
  },
};

export const groupFlagContentCms = {
  do_not: {
    value: 0,
    text: i18n.t("content-cms.do_not"),
  },
  do: {
    value: 1,
    text: i18n.t("content-cms.do"),
  },
};

export const mediaKindContentCms = {
  video: {
    value: "VIDEO",
    text: i18n.t("content-cms.video"),
  },
  audio: {
    value: "AUDIO",
    text: i18n.t("content-cms.audio"),
  },
  pdf: {
    value: "PDF",
    text: i18n.t("content-cms.pdf"),
  },
  column: {
    value: "COLUMN",
    text: i18n.t("content-cms.column"),
  },
};

export const mainSourceTypeContentCms = {
  file: {
    value: 1,
    text: i18n.t("content-cms.media_source_type_file"),
  },
  external_url: {
    value: 2,
    text: i18n.t("content-cms.media_source_type_external_url"),
  },
};

export const digestSourceTypeContentCms = {
  file: {
    value: 1,
    text: i18n.t("content-cms.media_source_type_file"),
  },
  external_url: {
    value: 2,
    text: i18n.t("content-cms.media_source_type_external_url"),
  },
};

export const mediaKindAllowExtension = {
  video: {
    value: ".mp4,.mov",
  },
  pdf: {
    value: ".pdf",
  },
  audio: {
    value: ".mp3",
  },
};

export const fileTypeRegex = /mpeg$|quicktime$/;

export const accessContentType = {
  single: {
    value: 0,
    text: i18n.t("access-content.single_content"),
  },
  group: {
    value: 1,
    text: i18n.t("access-content.group_content"),
  },
};

export const accessContentMediaType = {
  video: {
    value: "VIDEO",
    text: i18n.t("content-cms.video"),
  },
  audio: {
    value: "AUDIO",
    text: i18n.t("content-cms.audio"),
  },
  pdf: {
    value: "PDF",
    text: i18n.t("content-cms.pdf"),
  },
};

export const accessContentStatus = {
  active: {
    value: 1,
    text: i18n.t("panel.label_status_active"),
  },
  inactive: {
    value: 0,
    text: i18n.t("panel.label_status_inactive"),
  },
};

export const accessContentPushNotificationFlag = {
  do: {
    value: 1,
    text: i18n.t("panel.do"),
  },
  do_not: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
};

export const accessContentAllowGuestFlag = {
  do: {
    value: 1,
    text: i18n.t("panel.do"),
  },
  do_not: {
    value: 0,
    text: i18n.t("panel.do_not"),
  },
};

export const accessContentAlwaysVisibleFlag = {
  always_visible: {
    value: 1,
    text: i18n.t("access-content.always_visible"),
  },
  displayed_only_when_meet_conditions: {
    value: 2,
    text: i18n.t("access-content.displayed_only_when_meet_conditions"),
  },
};

export const requiredLoginFlag = {
  free_content_login_required: {
    value: 1,
    text: i18n.t("access-content.free_content_login_required"),
  },
  free_content_no_login_required: {
    value: 0,
    text: i18n.t("access-content.free_content_no_login_required"),
  },
};

export const accessContentAvailableType = {
  not_specified: {
    value: 1,
    text: i18n.t("access-content.not_specified"),
  },
  specify_number_of_days: {
    value: 2,
    text: i18n.t("access-content.specify_number_of_days"),
  },
  period_specification: {
    value: 3,
    text: i18n.t("access-content.period_specification"),
  },
};

export const renewAlertFlagOptions = {
  not_use: {
    value: 0,
    text: i18n.t("member-type.not_use"),
  },
  use: {
    value: 1,
    text: i18n.t("member-type.use"),
  },
};

export const accessManageFlag = {
  do: {
    value: 1,
    text: i18n.t("content-cms.do"),
  },
  do_not: {
    value: 0,
    text: i18n.t("content-cms.do_not"),
  },
};

export const customizeMenuType = {
  none: {
    value: 1,
    text: i18n.t("functional-menu.label_customize_menu_none"),
  },
  urlExternal: {
    value: 2,
    text: i18n.t("functional-menu.label_customize_menu_url_external"),
  },
  blockCategory: {
    value: 3,
    text: i18n.t("functional-menu.label_customize_menu_block_category"),
  },
};

export const masterType = {
  extension_item_master: {
    value: 1,
    text: i18n.t("master-category-customization.extension_item_master"),
  },
  category_master: {
    value: 2,
    text: i18n.t("master-category-customization.category_master"),
  },
};

export const autoPaymentFlag = {
  do: {
    value: 1,
    text: i18n.t("csv-setting.label_auto_payment_do"),
  },
  not: {
    value: 0,
    text: i18n.t("csv-setting.label_auto_payment_not_do"),
  },
};

export const customizePageSetting = {
  web: {
    value: 1,
    text: i18n.t("customize-page.label_setting_web_app"),
  },
  mobile: {
    value: 2,
    text: i18n.t("customize-page.label_setting_mobile_app"),
  },
};

export const platFormTypeCustomizeMenu = {
  mobile: {
    value: 4,
  },
  web: {
    value: 2,
  },
};

export const moduleStyleTypeMobile = {
  magazine: {
    value: 1,
    text: i18n.t("customize-page.label_magazine"),
    image_desc: "/img/customize-page/magazine.png",
  },
  smallCard: {
    value: 2,
    text: i18n.t("customize-page.label_small_card"),
    image_desc: "/img/customize-page/small_card.png",
  },
  largeCard: {
    value: 3,
    text: i18n.t("customize-page.label_large_card"),
    image_desc: "/img/customize-page/large_card.png",
  },
  imageCarousel: {
    value: 4,
    text: i18n.t("customize-page.label_image_carousel"),
    image_desc: "/img/customize-page/img_carousel.gif",
  },
  cardCarousel: {
    value: 5,
    text: i18n.t("customize-page.label_card_carousel"),
    image_desc: "/img/customize-page/card_carousel.gif",
  },
};

export const applyConditionType = {
  general_cms_component: {
    value: 1,
    text: i18n.t("apply-condition.general_cms_components"),
  },
  additional_cms_component: {
    value: 2,
    text: i18n.t("apply-condition.additional_cms_components"),
  },
};

export const multipleFlag = {
  single: {
    value: 0,
    text: i18n.t("apply-condition.single"),
  },
  multi: {
    value: 1,
    text: i18n.t("apply-condition.multi"),
  },
};

export const csvEventModuleType = {
  event: {
    value: 1,
  },
  eventParticipant: {
    value: 2,
  },
};

export const csvAccountModuleType = {
  admin: {
    value: 1,
  },
  member: {
    value: 2,
  },
};

export const accountIntegrateType = {
  facebook: {
    value: "FACEBOOK",
    text: "Facebook",
  },
  x: {
    value: "X",
    text: "X",
  },
  google: {
    value: "GOOGLE",
    text: "Google",
  },
  apple: {
    value: "APPLE",
    text: "Apple",
  },
};

export const roundingType = {
  round_up: {
    value: "ROUND_UP",
  },
  round_down: {
    value: "ROUND_DOWN",
  },
  round_off: {
    value: "ROUND_OFF",
  },
};

export const taxType = {
  exclusive_tax: {
    value: 1,
  },
  inclusive_tax: {
    value: 2,
  },
};
