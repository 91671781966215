// import domain from '@/middlewares/manage/domain';
import authManage from "@/middlewares/manage/auth";
import selectOrganization from "@/middlewares/manage/select-organization";

export default {
  path: "admin-accounts",
  name: "AccountManage",
  component: () =>
    import(
      /* webpackChunkName: "account-manage-layout-view" */ "@/views/Manage/Account/LayoutView.vue"
    ),
  redirect: { name: "AccountSearch" },
  children: [
    {
      path: "", // /manage/account
      name: "AccountSearch",
      component: () =>
        import(
          /* webpackChunkName: "account-manage-searching-view" */ "@/views/Manage/Account/Search.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "create", // /manage/account/create
      name: "AccountCreate",
      component: () =>
        import(
          /* webpackChunkName: "account-manage-create-view" */ "@/views/Manage/Account/Create.vue"
        ),
      props: { default: true },
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "edit/:id", // /manage/account/edit/1
      name: "AccountEdit",
      component: () =>
        import(
          /* webpackChunkName: "account-manage-edit-view" */ "@/views/Manage/Account/Edit.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
    {
      path: "import-csv",
      name: "AccountImportCsv",
      component: () =>
        import(
          /* webpackChunkName: "account-manage-import-csv-view" */ "@/views/Manage/Account/ImportCsvAdmin.vue"
        ),
      meta: {
        middleware: [authManage, selectOrganization],
      },
    },
  ],
};
